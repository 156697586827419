<template>
  <div class="container-fluid">
    <div class="row">
      <sidebar></sidebar>
      <nav-header></nav-header>

      <!-- MAIN CONTENT start -->
      <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto">
        <sidebar-sm></sidebar-sm>
        <breadcrumb :items="pageData.breadcrumb"></breadcrumb>
        <!-- SCHEDULE -->
        <section class="schedule-section">
          <div class="row">
            <div class="col-12">
              <page-title :title="pageData.title"></page-title>
              <div class="schedule">
                <sk-list v-if="!currentSchedule || currentSchedule.length === 0" :items-count="5" height="0.02"></sk-list>
                <table v-else class="table">
                  <thead>
                  <tr>
                    <th>{{ $t('schedule.group') }}</th>
                    <th>{{ $t('schedule.subject') }}</th>
                    <th>{{ $t('schedule.day') }}</th>
                    <th style="min-width: 100px">{{ $t('schedule.time') }}</th>
                    <th>{{ $t('schedule.date') }}</th>
                    <th style="width: 160px">{{ $t('schedule.type') }}</th>
                    <th>{{ $t('schedule.lecturer') }}</th>
                    <th>{{ $t('schedule.reading-video-resources') }}</th>
                    <th class="text-center">{{ $t('schedule.syllabus') }}</th>
                    <th class="text-center">{{ $t('schedule.message') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="schedule of regularSchedules">
                    <td :data-label="$t('schedule.group')">{{ schedule.group }}</td>
                    <td :data-label="$t('schedule.subject')">{{ helpers.getLocalizedField(schedule, 'subject', 'subject_eng') }}</td>
                    <td :data-label="$t('schedule.day')">{{ helpers.getLocalizedField(schedule, 'day', 'day_eng') }}</td>
                    <td class="text-left text-lg-center" :data-label="$t('schedule.time')">
                      <div class="small" v-if="schedule.start_date && schedule.start_date !== ''">
                        {{ schedule.start_date }}<br />{{ schedule.end_date }}
                      </div>
                      {{ schedule.time }} - {{ schedule.time_end }}

                    </td>
                    <td :data-label="$t('schedule.date')">{{ schedule.holddate ? schedule.holddate : '-' }}</td>
                    <td :data-label="$t('schedule.type')">
                      <span v-if="schedule.start_date && schedule.start_date !== ''">{{ $t('registration.curation') }}, {{ helpers.getLocalizedField(schedule, 'room') }}</span>
                      <a class="red-hover" v-if="schedule.roomID == helpers.getDistanceRoomID()" href="#">{{ $t('schedule.distance') }}</a>
                      <span v-else>{{ $t('schedule.room') + ': ' + helpers.getLocalizedField(schedule, 'room') }}</span>
                    </td>
                    <td :data-label="$t('schedule.lecturer')">{{ helpers.getLocalizedField(schedule, 'lecturer') }}</td>
                    <td :data-label="$t('schedule.reading-resources')">
                      <span v-if="hasResources(schedule)" class="cursor-pointer text-danger" @click="showResourcePopup(schedule)">{{ $t('schedule.reading-video') }}</span>
                    </td>
                    <td :data-label="$t('schedule.syllabus')">
                      <a v-if="schedule.syllabusID && schedule.syllabusID != 0" target="_blank" :href="'//online.ug.edu.ge/sillabus_system/syllabus_print.php?syllabusID='+ schedule.syllabusID" class="ic-link syllabus-icon"></a>
                    </td>
                    <td :data-label="$t('schedule.message')">
                      <a href="#" class="ic-link message-icon"></a>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-12 mt-5" v-if="recoverySchedules && recoverySchedules.length > 0">
              <page-title :title="$t('schedule.recovery')"></page-title>
              <div class="schedule">
                <table class="table">
                  <thead>
                  <tr>
                    <th>{{ $t('schedule.group') }}</th>
                    <th>{{ $t('schedule.subject') }}</th>
                    <th>{{ $t('schedule.day') }}</th>
                    <th style="min-width: 100px">{{ $t('schedule.time') }}</th>
                    <th>{{ $t('schedule.date') }}</th>
                    <th style="width: 160px">{{ $t('schedule.type') }}</th>
                    <th>{{ $t('schedule.lecturer') }}</th>
                    <th>{{ $t('schedule.reading-video-resources') }}</th>
                    <th class="text-center">{{ $t('schedule.syllabus') }}</th>
                    <th class="text-center">{{ $t('schedule.message') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="schedule of recoverySchedules">
                    <td :data-label="$t('schedule.group')">{{ schedule.group }}</td>
                    <td :data-label="$t('schedule.subject')">{{ helpers.getLocalizedField(schedule, 'subject', 'subject_eng') }}</td>
                    <td :data-label="$t('schedule.day')">{{ helpers.getLocalizedField(schedule, 'day', 'day_eng') }}</td>
                    <td class="text-left text-lg-center" :data-label="$t('schedule.time')">
                      <div class="small" v-if="schedule.start_date && schedule.start_date !== ''">
                        {{ schedule.start_date }}<br />{{ schedule.end_date }}
                      </div>
                      {{ schedule.time }}

                    </td>
                    <td :data-label="$t('schedule.date')">{{ schedule.holddate ? schedule.holddate : '-' }}</td>
                    <td :data-label="$t('schedule.type')">
                      <span v-if="schedule.start_date && schedule.start_date !== ''">{{ $t('registration.curation') }}, {{ helpers.getLocalizedField(schedule, 'room') }}</span>
                      <a class="red-hover" v-if="schedule.roomID == helpers.getDistanceRoomID()" href="#">{{ $t('schedule.distance') }}</a>
                      <span v-else>{{ $t('schedule.room') + ': ' + helpers.getLocalizedField(schedule, 'room') }}</span>
                    </td>
                    <td :data-label="$t('schedule.lecturer')">{{ helpers.getLocalizedField(schedule, 'lecturer') }}</td>
                    <td :data-label="$t('schedule.reading-resources')">
                      <span v-if="hasResources(schedule)" class="cursor-pointer text-danger" @click="showResourcePopup(schedule)">{{ $t('schedule.reading-video') }}</span>
                    </td>
                    <td :data-label="$t('schedule.syllabus')">
                      <a v-if="schedule.syllabusID && schedule.syllabusID != 0" target="_blank" :href="'//online.ug.edu.ge/sillabus_system/syllabus_print.php?syllabusID='+ schedule.syllabusID" class="ic-link syllabus-icon"></a>
                    </td>
                    <td :data-label="$t('schedule.message')">
                      <a href="#" class="ic-link message-icon"></a>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <resource-popup :schedule="chosenSchedule"></resource-popup>
        </section>
      </main>
      <!-- MAIN CONTENT end -->
    </div>
  </div>
</template>

<script>
  import sidebar from '../components/navbar/sidebar';
  import sidebarSm from '../components/navbar/sidebar-sm';
  import appHeader from '../components/app-header';
  import pageHeader from '../components/page/page-header';
  import NavHeader from '../components/navbar/nav-header';
  import Breadcrumb from '../components/page/breadcrumb';
  import PageTitle from '../components/page/page-title';
  import SkList from '../components/skeletons/sk-list';
  import moment from 'moment';
  import ResourcePopup from '../components/schedule/resources-popup';
  import ScheduleResource from '../api/scheduleResource';

  const scheduleResource = new ScheduleResource();

  export default {
    name: 'schedule',
    components: { ResourcePopup, SkList, PageTitle, Breadcrumb, NavHeader, sidebar, sidebarSm, appHeader, pageHeader },

    data() {
      return {
        chosenSchedule: null,
        currentSchedule: []
      }
    },

    computed: {
      pageData() {
        return {
          title: this.$t('schedule.title'),
          breadcrumb: [{
            icon: '',
            title: this.$t('home.title'),
            link: '/',
            isActive: false,
          },
            {
              icon: '',
              title: this.$t('schedule.title'),
              link: '/schedule',
              isActive: true,
            }],
        };
      },

      regularSchedules() {
        return this.currentSchedule.filter(o => o.schedule_typeID == 1);
      },

      recoverySchedules() {
        return this.currentSchedule.filter(o => o.schedule_typeID == 2);
      }
    },

    mounted() {
      scheduleResource.currentSchedule().then(response => {
        this.currentSchedule = response.data.data;
      })
    },

    methods: {
      getSubjectTime(time) {
        return moment(time, 'HH:mm:ss').format('HH:mm');
      },

      hasResources(schedule) {
        return parseInt(schedule.has_resources) === 1;
      },

      showResourcePopup(schedule) {
        this.chosenSchedule = schedule;
        this.$bvModal.show('resource-popup');
      }
    }
  };
</script>

<style scoped>
  @media screen and (min-width:1200px){
    .schedule .table tbody td{
      word-break: unset;
    }
    .schedule .table thead th,
    .table tbody td{
      padding: 1.45rem 1rem;
    }
    .table tbody td:nth-child(2){
      max-width: 35rem;
    }
  }
  @media screen and (min-width:1390px){
    .table thead th:nth-child(8){
      min-width: 18rem;
    }
  }
  @media screen and (min-width: 996px) and (max-width:1300px){
    .schedule .table tbody td {
      word-break: break-word;
    }
    .schedule .table thead tr th:first-child ,
    .schedule table tr td:first-child {
      padding-left: 2rem;
    }
    .schedule .table thead tr th:last-child ,
    .schedule table tr td:last-child{
      padding-right: 2rem;
    }
  }

  @media only screen and (min-width: 576px) and (max-width: 1115px){
    .schedule .table tbody td::before{
      min-width: 10rem;
      word-break: break-word;
    }
  }
  @media (max-width: 576px){
    .schedule .table tbody td::before{
      min-width: 15rem;
    }
    .schedule .table tbody td {
      word-break: break-word;
    }
  }
</style>
